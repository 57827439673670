import { Injectable } from '@angular/core';

import { User } from '@firebase/auth';

import { BehaviorSubject } from 'rxjs';

import { AfsService } from '@services/afs.service';
import { DtService } from '@services/dt.service';
import { EnvService } from '@services/env.service';
import { LsService } from '@services/ls.service';
import { UtilitiesService } from '@services/utilities.service';

import { permissionList, UserPermission, UserPreferences, UserSettings } from '@shared/user.interface';

type PrefType = string | number | boolean;

@Injectable({ providedIn: 'root' })
export class UserState {
  public isDemo$ = new BehaviorSubject<boolean>(false);

  private _isInitialized = false;

  public settings?: UserSettings;

  public user = {
    uid: '',
    email: '',
    emailVerified: false,
    name: '',
    permissions: [] as UserPermission[],
    locationIDs: [] as string[],
    parentIDs: [] as string[],
  };

  public canEdit: { [id: string]: boolean } = {};
  public canView: { [id: string]: boolean } = {};
  public noAccess: { [id: string]: boolean } = {};

  public masterUser = false;
  public betaUser = false;

  public demo = false;

  constructor(
    private afs: AfsService,
    private dt: DtService,
    private env: EnvService,
    private ls: LsService,
    private util: UtilitiesService,
  ) { }

  public resetState(): void {
    this.user.uid = '';
    this.user.email = '';
    this.user.emailVerified = false;
    this.user.name = '';
    this.user.permissions = [];
    this.user.locationIDs = [];
    this.user.parentIDs = [];


    this.masterUser = false;
    this.betaUser = false;

    this.demo = false;
  }

  public setUser(user: User, clientID: string): void {
    this.user.uid = user.uid;
    this.user.email = user.email ?? '';
    this.user.emailVerified = user.emailVerified ?? false;
    this.user.name = user.displayName ?? '';

    this.demo = this.isDemo(clientID);

    this.masterUser =
      user.uid === 'x1KUecXkmPNsmaSSdxbMOaBZBAn1' ||  // Prod
      user.uid === 'yCgoUcikWKNG5168D08T2ODWCsv1' ||  // Dev
      user.uid === 'wl97aAbycaNtSWdToDIsWqJ6p6i1';    // Localhost
  }

  private isDemo(clientID: string): boolean {
    const demoUser = clientID === 'GYRiVq9Vh';
    this.isDemo$.next(demoUser);
    return demoUser;
  }

  public async initSettings(clientID: string): Promise<void> {
    this.settings = await this.afs.getDocument<UserSettings>('users', this.user.uid, false, 3);
    if (!this.settings) {
      console.error(`initSettings failed for ${this.user.uid}, document not found`);
      return;
    }

    let updateNeeded = false;

    this.demo = this.isDemo(clientID);  // Refresh demo status in case it changed
    this.user.permissions = this.initPermissions(this.settings.permissions ?? []);
    this.user.parentIDs = this.settings.parentIDs ?? [];
    this.user.locationIDs = this.settings.locationIDs ?? [];

    this.betaUser = this.settings.betaUser ?? false;

    if (!this.masterUser) {
      if (!this.settings.firstUse) this.settings.firstUse = this.dt.nowStr;
      this.settings.uses = (this.settings.uses ?? 0) + 1;
      this.settings.latestUse = this.dt.nowStr;
      this.settings.latestVersion = this.env.version;
      updateNeeded = true;
    }

    if (this.initPrefs()) {
      updateNeeded = true;
    }

    // Unpause any activities dependent on user settings
    this._isInitialized = true;

    if (updateNeeded) {
      void this.afs.setDocument('users', this.user.uid, this.settings, { merge: true }, 3);
    }
  }

  public isInitialized(): Promise<void> {
    return new Promise(resolve => {
      if (this._isInitialized) {
        resolve();
        return;
      }

      const interval = setInterval(() => {
        if (this._isInitialized) {
          clearInterval(interval);
          resolve();
        }
      }, 50);
    });
  }

  private initPermissions(permissions: UserPermission[]): UserPermission[] {
    const newUserPermissions = [];
    for (const permission of permissionList) {
      const match = permissions.find(p => p.id === permission.id);
      if (permission.id === 'locations' && match?.state === 2) match.state = 0; // Fix default
      const userPermission = match ?? { id: permission.id, state: 0 };
      newUserPermissions.push(userPermission);

      // Update permission flags for UI templates
      this.canEdit[permission.id] = userPermission.state === 0 && !this.demo;  // Can edit
      this.canView[permission.id] = userPermission.state !== 2 || this.demo;  // Can view (including 'edit' permission)
      this.noAccess[permission.id] = userPermission.state === 2 && !this.demo;  // No access
    }
    return newUserPermissions;
  }

  private initPrefs(): boolean {
    const savedPrefs = this.util.deepClone(this.settings?.prefs);

    if (this.settings) {
      this.settings.prefs = {
        authPersistence: this.getValue('authPersistence', 'local') as string,
        byHourMetricID: this.getValue('byHourMetricID', 'sales') as string,
        comparisonDayCustomOffset: this.getValue('comparisonDayCustomOffset', 364) as number,
        comparisonDayID: this.getValue('comparisonDayID', 'prevWeek') as string,
        comparisonShowEvent: this.getValue('comparisonShowEvent', false) as boolean,
        costType: this.getValue('costType', '$') as string,
        demoPOS: this.getValue('demoPOS', 'CL') as string,
        groupByCategory: this.getValue('groupByCategory', true) as boolean,
        heatmapFilterAnimateBy: this.getValue('heatmapFilterAnimateBy', 'none') as string,
        heatmapFilterDaysOfWeek: this.getValue('heatmapFilterDaysOfWeek', '0123456') as string,
        heatmapFilterGroupBy: this.getValue('heatmapFilterGroupBy', 'channelID') as string,
        heatmapFilterLocations: this.getValue('heatmapFilterLocations', 'current') as string,
        heatmapFilterMetric: this.getValue('heatmapFilterMetric', 'sales') as string,
        heatmapFilterShowThru: this.getValue('heatmapFilterShowThru', 'yesterday') as string,
        heatmapFilterShowThruCustomDate: this.getValue('heatmapFilterShowThruCustomDate', this.dt.yesterdayStr) as string,
        heatmapFilterTimeframe: this.getValue('heatmapFilterTimeframe', 'month') as string,
        heatmapFilterTimeframeCustomTimeframe: this.getValue('heatmapFilterTimeframeCustomTimeframe', 6) as number,
        heatmapFilterTimeframeCustomUnits: this.getValue('heatmapFilterTimeframeCustomUnits', 'months') as string,
        heatmapFilterTimeOfDay: this.getValue('heatmapFilterTimeOfDay', 'typical') as string,
        heatmapFilterTimeOfDayEnd: this.getValue('heatmapFilterTimeOfDayEnd', 23) as number,
        heatmapFilterTimeOfDayStart: this.getValue('heatmapFilterTimeOfDayStart', 0) as number,
        hiddenChannels: this.getValue('hiddenChannels', '') as string,
        honorProfitVisibility: this.getValue('honorProfitVisibility', false) as boolean,
        includeInactiveCategories: this.getValue('includeInactiveCategories', false) as boolean,
        includeInactiveItems: this.getValue('includeInactiveItems', false) as boolean,
        metrics: this.getValue('metrics', '') as string,
        lastError: this.getValue('lastError', '') as string,
        locationID: this.getValue('locationID', '') as string,
        promptForGoogle: this.getValue('promptForGoogle', true) as boolean,
        showDemoMessage: this.getValue('showDemoMessage', true, true) as boolean,
        showHistoryStatus: this.getValue('showHistoryStatus', 'none') as string,
        showMigrationAlert: this.getValue('showMigrationAlert', true, true) as boolean,
        showTrendLine: this.getValue('showTrendLine', true, true) as boolean,
        stackTrends: this.getValue('stackTrends', true, true) as boolean,
        stack100: this.getValue('stack100', false, true) as boolean,
        showRefreshMessage: this.getValue('showRefreshMessage', true, true) as boolean,
        trendsFilterAggregation: this.getValue('trendsFilterAggregation', 'day') as string,
        trendsFilterAggregationMTD: this.getValue('trendsFilterAggregationMTD', 0) as number,
        trendsFilterAggregationQTD: this.getValue('trendsFilterAggregationQTD', 0) as number,
        trendsFilterAggregationYTD: this.getValue('trendsFilterAggregationYTD', 0) as number,
        trendsFilterComparison: this.getValue('trendsFilterComparison', 'none') as string,
        trendsFilterDayOfWeek: this.getValue('trendsFilterDayOfWeek', 0) as number,
        trendsFilterEvent: this.getValue('trendsFilterEvent', 'x2mWJUMzf') as string,
        trendsFilterGroupBy: this.getValue('trendsFilterGroupBy', 'channelID') as string,
        trendsFilterLocations: this.getValue('trendsFilterLocations', 'current') as string,
        trendsFilterMetric: this.getValue('trendsFilterMetric', 'sales') as string,
        trendsFilterQuantity: this.getValue('trendsFilterQuantity', '7days') as string,
        trendsFilterShowThru: this.getValue('trendsFilterShowThru', 'yesterday') as string,
        trendsFilterShowThruCustomDate: this.getValue('trendsFilterShowThruCustomDate', this.dt.yesterdayStr) as string,
        trendsFilterTimeOfDay: this.getValue('trendsFilterTimeOfDay', 'none') as string,
        trendsFilterTimeOfDayEnd: this.getValue('trendsFilterTimeOfDayEnd', 23) as number,
        trendsFilterTimeOfDayStart: this.getValue('trendsFilterTimeOfDayStart', 0) as number,
        zoomFactor: this.getValue('zoomFactor', 1) as number,
      };
    }

    return this.util.isDiffDeep(savedPrefs, this.settings?.prefs);
  }

  public getPref(pref: string, value?: PrefType): PrefType {
    return this.getValue(pref, value ?? '');
  }

  public setPref(pref: string, value: PrefType): void {
    this.ls.set(pref, String(value));

    // Don't save preferences until a user is created
    if (!this.user.uid) return;

    const userSetting = this.settings?.prefs?.[pref as keyof UserPreferences];
    if (userSetting && userSetting === value) return;

    if (this.settings?.prefs && !Object.hasOwn(this.settings.prefs, pref)) {
      console.error('APP user.setPref: Invalid user preference: ', pref, value);
      return;
    }

    if (this.settings?.prefs && this.settings.prefs[pref as keyof UserPreferences] !== value) {
      this.settings.prefs = {
        ...this.settings.prefs,
        [pref as keyof UserPreferences]: value,
      };
      void this.afs.setDocument('users', this.user.uid, this.settings, { merge: true }, 3);  // Update in background
    }
  }

  private getValue(pref: string, defaultValue: PrefType, convertToBoolean = false): PrefType {
    const currentValue = this.settings?.prefs?.[pref as keyof UserPreferences];
    let ls;

    if (currentValue === undefined) {
      ls = this.ls.get(pref);
      if (convertToBoolean) {
        ls = ls === '1' ? 'true' : ls === '0' ? 'false' : ls;
      }
    }

    if (typeof defaultValue === 'boolean') {
      return currentValue ?? (ls ? JSON.parse(ls) : defaultValue);
    } else if (typeof defaultValue === 'number') {
      return currentValue ?? (ls ? Number(ls) : defaultValue);
    } else {
      return currentValue ?? (ls || defaultValue);
    }
  }
}
