import { Injectable } from '@angular/core';

import { AlertService } from '@services/alert.service';
import { DeviceService } from '@services/device.service';

import { UserState } from '@state/user.state';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;

  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed',
    platform: string,
  }>;

  prompt(): Promise<void>;
}

@Injectable({ providedIn: 'root' })
export class A2hsService {
  public isInstalled = false;

  private deferredPrompt: Event | BeforeInstallPromptEvent | undefined;
  private readyToInstall = false;

  constructor(
    private alert: AlertService,
    private device: DeviceService,
    private userState: UserState,
  ) {
    this.isInstalled = window.matchMedia('(display-mode: standalone)').matches || // Standard PWA check
      (window.navigator as any).standalone || // iOS Safari
      document.referrer.includes('android-app://'); // Android WebView detection
  }

  public listenForInstallPrompt(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.readyToInstall = true;
    }, { once: true });

    window.addEventListener('appinstalled', () => this.isInstalled = true, { once: true });
  }

  public async showInstallPrompt(): Promise<void> {
    if (!this.device.mobile || this.userState.demo) return;

    if (this.device.iOS) {
      if (this.device.iPhone) {
        await this.alert.messageWithImage('', '', '/assets/images/a2hs-iphone.png', 315, 508, 'Got it!');
      } else {
        await this.alert.messageWithImage('', '', '/assets/images/a2hs-ipad.png', 315, 508, 'Got it!');
      }
    } else if (this.readyToInstall && this.deferredPrompt) {
      if (await this.alert.confirm('Install App',
        `The Sprk™ app can be added to your home screen so it's easier to access next time.\n\n` +
        `To do this, tap <b>Install</b> when prompted on the next screen.`, 'info', 'Show Prompt', 'Skip for Now')) {
        await (this.deferredPrompt as BeforeInstallPromptEvent)?.prompt();
      }
    } else {
      await this.alert.messageWithImage('', '', '/assets/images/a2hs-android.png', 315, 522, 'Got it!');
    }
  }
}
