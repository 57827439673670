export interface ChannelSettings {
  id: string;
  shortID: string;
  sourceID: string;
  mappedID: string;
  name: string;
  fullName: string;
  shortName: string;
  visible: boolean;
  types: string[];
  fieldsShownInUI: string[],
  icon?: string;
  color: string;
  lightColor: string;
  contrastColor: string;
}

export const channelSettings: ChannelSettings[] = [
  {
    id: 'CL',
    shortID: 'C',
    sourceID: 'CL',
    mappedID: 'CL',
    name: 'Clover',
    fullName: 'Clover',
    shortName: 'Clover',
    visible: false,
    types: ['pos'],
    fieldsShownInUI: ['servchgs'],
    icon: 'assets/icons/clover.png',
    color: '',
    lightColor: '',
    contrastColor: '',
  },
  {
    id: 'DD',
    shortID: 'D',
    sourceID: 'DD',
    mappedID: 'DD',
    name: 'DoorDash',
    fullName: 'DoorDash',
    shortName: 'DoorDash',
    visible: false,
    types: ['delivery'],
    fieldsShownInUI: ['commission'],
    icon: 'assets/icons/doordash.png',
    color: '',
    lightColor: '',
    contrastColor: '',
  },
  {
    id: 'GH',
    shortID: 'G',
    sourceID: 'GH',
    mappedID: 'GH',
    name: 'GrubHub',
    fullName: 'GrubHub',
    shortName: 'GrubHub',
    visible: false,
    types: ['delivery'],
    fieldsShownInUI: ['commission'],
    icon: 'assets/icons/grubhub.png',
    color: '',
    lightColor: '',
    contrastColor: '',
  },
  {
    id: 'SHP',
    shortID: 'S',
    sourceID: 'SH',
    mappedID: 'SHP',
    name: 'Shopify POS',
    fullName: 'Shopify POS',
    shortName: 'Shopify',
    visible: false,
    types: ['pos'],
    fieldsShownInUI: ['mapChannel', 'servchgs'],
    icon: 'assets/icons/shopify-pos.png',
    color: '',
    lightColor: '',
    contrastColor: '',
  },
  {
    id: 'SHE',
    shortID: 'E',
    sourceID: 'SH',
    mappedID: 'SHE',
    name: 'Shopify Online',
    fullName: 'Shopify Online',
    shortName: 'Shopify',
    visible: false,
    types: ['ecommerce'],
    fieldsShownInUI: ['mapChannel', 'servchgs'],
    icon: 'assets/icons/shopify.png',
    color: '',
    lightColor: '',
    contrastColor: '',
  },
  {
    id: 'SQP',
    shortID: 'Q',
    sourceID: 'SQ',
    mappedID: 'SQP',
    name: 'Square POS',
    fullName: 'Square POS',
    shortName: 'Square',
    visible: false,
    types: ['pos'],
    fieldsShownInUI: ['mapChannel', 'servchgs'],
    icon: 'assets/icons/square.png',
    color: '',
    lightColor: '',
    contrastColor: '',
  },
  {
    id: 'SQE',
    shortID: 'F',
    sourceID: 'SQ',
    mappedID: 'SQE',
    name: 'Square Online',
    fullName: 'Square Online',
    shortName: 'Square',
    visible: false,
    types: ['ecommerce'],
    fieldsShownInUI: ['mapChannel', 'servchgs'],
    icon: 'assets/icons/square.png',
    color: '',
    lightColor: '',
    contrastColor: '',
  },
  {
    id: 'UE',
    shortID: 'U',
    sourceID: 'UE',
    mappedID: 'UE',
    name: 'Uber Eats',
    fullName: 'Uber Eats',
    shortName: 'Uber Eats',
    visible: false,
    types: ['delivery'],
    fieldsShownInUI: ['commission'],
    icon: 'assets/icons/ubereats.png',
    color: '',
    lightColor: '',
    contrastColor: '',
  },
];